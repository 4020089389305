.company-services {
    padding-top: 0!important;

    &__container {
        text-align: center;
    }

    h3 {
        margin: 60px 0;
    }
}