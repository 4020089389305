.aboutCompany {
    padding-top: 0px!important;

    background: #fff;

    &__container {
        // text-align: center;
        display: flex;
        gap: 25px;
        margin-top: 100px!important;
        @media(max-width: 1070px) {
            flex-direction: column;
        }
    }
    &__poster{
        width: 50%;
        @media(max-width: 1070px) {
            width: 100%;
            height: 85vw;
        }
    }
    &__poster img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    &__descr {
        width: 50%;
        @media(max-width: 1070px) {
            width: 100%;
        }
    }

    &__text {
        margin-top: 20px;
        font-size: 20px;
        font-weight: 300;
        color: var(--color-text3);
    }
    &__text + .aboutCompany__text {
        // margin-top: 20px;
    }
}

.advantages {
    background: #f6f6f6;
    &__container {
        text-align: right;

    }
}

.benefits {
    background: #fff;
    &__container {
    }
}