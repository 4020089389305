.contactsPage__wrapper {
    background: url('../../assets/img/contacts-bg.jpg') no-repeat 50% 10%;
}

.contacts {
    background: #f8f6ee;
    margin-bottom: 310px;

    @media(max-width: 1074px) {
        margin-bottom: 650px;
    }

    &__container {
        position: relative;
    }
    &__form {
        background: #fff;
        position: absolute;
        top: 0;
        right: 2%;
        max-width: 500px;
        width: 100%;
        padding: 40px;
        @media(max-width: 1074px) {
            left: 10px;
            top: 230px;
            width: auto;
            right: 10px;
        }
        @media(max-width: 812px) {
            top: 300px;
        }
        @media(max-width: 460px) {
            top: 440px;
        } 
        @media(max-width: 358px) {
            top: 550px;
        } 
    }
    &__title{
        max-width: 45%;
        @media(max-width: 1074px) {
            max-width: 100%;
        }
        h3 {
            text-align: start;
        }
    }
}

.location {
    background: #fff;
    &__container {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        justify-content: space-between;
    }
    &__map {
        flex: 1 1 350px;
        height: 350px;
        overflow: hidden;
    }
    &__map iframe {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    &__info {
        width: 218px;
        margin-right: 10%;
    }
    &__info-item {
        line-height: 2;
        color: var(--color-text3);
    }
    &__info-item a {
        transition: all 0.3s ease;
        text-decoration: none;
    }
    &__info-item a:hover {
        color: var(--color-active);
    }
}