.logo {
    height: 50px;
    display: block;
    width: 100px;
    svg{
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;

    }
    &:hover svg{
        transform: scale(1.1);
    }
}