.dropdown {
    font-size: inherit;
    width: 100%;

    &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        // color: #27292e;
        color: var(--color-text)
    }
    &__title {
        font-size: 20px;
    }
    &__btn {
        width: 15px;
        height: 15px;
        transform: rotate(90deg);
        svg{
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: 0.3s all ease;
            fill: var(--color-text);
        }
    }
    &__body{
        max-height: 0;
        overflow: hidden;
        padding-left: 10px;
        transition: 0.3s all ease;
        font-size: 15px;
    }
    &__body.active {
        max-height: 300px;
        padding-bottom: 5px;
    }
    &__btn.active svg{
        transform: rotate(180deg);
    }
}