.footer {
    padding: 35px 0;
    color: var(--color-text);
    line-height: 1.2;

    &__nav-menu {
        flex-direction: column;
        gap: 5px!important;
    }
    &__nav .menu__list{
        flex-direction: column;
        gap: 6px;
        font-size: 16px;
    }
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        @media(max-width: 650px ) {
            flex-direction: column;
            align-items: start;
            gap: 50px;
        }
    }

    &__logo {
        display: block;
        height: 90px;
        width: 100pz;
    }
    &__logo svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__socials {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &__socials-item {
        display: block;
        width: 25px;
        height: 25px;
        transition: 0.3s all ease;
    }
    &__socials-item:hover {
        transform: scale(1.3);
    }

    &__socials-item svg{
        object-fit: contain;
        width: 100%;
    }
    &__contacts-title {
        margin-bottom: 6px;
    }
    &__contacts-item {
        margin-bottom: 6px;
    }
    &__contacts-item:last-child {
        margin-bottom: 0px;
    }
}