.form-input__wrapper {
    width: 100%;
    position: relative;
    height: 60px;
    overflow: hidden;
    padding: 5px 0;
    z-index: 25;
    border-bottom: 1px solid var(--color-text3);
    transition: all 0.3s ease;
  }
  
  .form-input__wrapper input {
    width: 100%;
    height: 100%;
    color: var(--color-text2);
    padding-top: 20px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid var(--color-text3);

  }
  
  .content-name {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding-bottom: 5px;
    transition: all 0.3s ease;
    z-index: -1;
    font-weight: 300;
  }
  .form-input__wrapper input:focus {
    
  }
  .form-input__wrapper input:focus + .label-name .content-name,
  .form-input__wrapper input:valid + .label-name .content-name {
    transform: translateY(-100%);
    font-size: 14px;
    left: 0px;
    color: var(--color-active);
  }

  .form-input__wrapper input:focus {
    border-bottom: 1px solid red;
  }
  .form-input__wrapper input:focus + .label-name::after,
  .form-input__wrapper input:valid + .label-name::after {
    transform: translateX(0%);
  }
  