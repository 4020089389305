.promo {
    position: relative;

    height: 100vh;
    background: url("../../assets/img/promo.webp") 50% 50%/cover no-repeat;

&:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
}
    &__title {
        display: flex;
        gap: 20px;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 35%;
        left: 50%;
        z-index: 10;
        transform: translateX(-50%);
        max-width: 740px;
        width: 100%;
        text-align: center;
    }
}