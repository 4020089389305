.reviews {
    background: #fff;
}

.swiper {
    padding-bottom: 60px;
    &-scrollbar {
        display: none;
    }
}
