.burger-menu {
    position: relative;
    z-index: 120;
    order: 2;

    &__list {
        width: 100vw;
        height: 100vh;
        padding-left: 30px;
        padding-top: 90px;
        position: absolute;
        top: -33px;
        left: 40vw;
        background: var(--color-background);
        pointer-events: auto;
        transition: transform .3s;
        will-change: transform;
        contain: paint;
        overflow: scroll;
        margin-bottom: 20px;
    }
    &__list.active {
        transform: translateX(calc(-30vw - 400px));
        @media (max-width: 550px) {
            transform: translateX(calc(-30vw - 330px));
        }
        @media (max-width: 410px) {
            transform: translateX(calc(-30vw - 250px));
        }
    }
    &__list.active body {
        overflow-y: hidden;
    }
    &__list:last-child{
        margin-bottom: 40px;
    }

    &__container {
        position: relative;
        display: inline-block;
        height: 50px;
        width: 50px;
        cursor: pointer;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        z-index: 10;
    }

    &__btn {
        width: 25px;
        height: 20px;
        position: relative;
        display: block;
        margin: -4px auto 0;
        top: 50%;
    }

    &__decor {
        width: 100%;
        height: 1px;
        display: block;
        position: relative;
        background: var(--color-text);
        -webkit-transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
   transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
   -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
    }

    &__decor.top {
        -webkit-transform: translateY(0px) rotate(0deg);
        transform: translateY(0px) rotate(0deg);
    }

    &__decor.bottom {
        -webkit-transform: translateY(6px) rotate(0deg);
        transform: translateY(6px) rotate(0deg);
    }

    &__container.active {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    &__contaiener.active .burger__menu-decor {
        -webkit-transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        -webkit-transition-delay: 0.2s;
        transition-delay: 0.2s;
    }
    &__container.active .top {
        -webkit-transform: translateY(4px) rotate(45deg);
        transform: translateY(4px) rotate(45deg);
        background: var(--color-text);
        box-shadow: 0 0 10px var(--btn-activeColor);

    }
    &__container.active .bottom {
        -webkit-transform: translateY(3px) rotate(-45deg);
          transform: translateY(3px) rotate(-45deg);
          background: var(--color-text);
          box-shadow: 0 0 10px var(--btn-activeColor);
    }
    &__title {
        max-width: 200px;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 16px;
        color: var(--color-active);
        margin-bottom: 15px;
        margin-top: 30px;
    }
    &__category {
        margin-left: 10px;
        max-width: 230px;
    }
    &__category:last-child{
        margin-bottom: 150px;
    }
    &__link {
        text-decoration: none;
        color: inherit;
        color: var(--color-text);
        font-size: 36px;
        position: relative;
    }
    &__link::after{
        content: '';
        position: absolute;
        width: 0;
        height: 1px;
        background: var(--color-text);
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        transition: 0.3s all ease;
    }
    &__link:hover::after{
        width: 100%;
    }
    &__item{
        font-size: 20px;
        margin-top: 16px;
        opacity: 0;
    }
    &__submenu {
        text-decoration: none;
        color: inherit;
        color: var(--color-text);
        font-size: 20px;
        text-transform: uppercase;
    }
    &__submenu-inner {
        max-height: 0;
        overflow: hidden;
    }
}

.backdrop {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 2;
    background: rgba($color: #000000, $alpha: 0.5);
    transition: 0.3s all ease;

    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.menu {
    &__list {
        display: flex;
        justify-content: center;
        gap: 40px;
        font-size: 16px;
    }

    &__item{
        opacity: 0;
    }
    &__list-item {
        text-decoration: none;
        color: var(--color-text);
        transition: 0.3s all ease;
        position: relative;
        cursor: pointer;

        // visibility: hidden;
    }
    &__list-item.active::after {
        width: 100%;
    }
    &__list-item:hover::after{
        width: 100%;
    }
    &__list-item::after{
        position: absolute;
        content: '';
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 1.5px;
        background: var(--color-active);
        transition: 0.3s all ease;

    }
}


@keyframes menuReveal {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}