.button {
    height: 100%;
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    color: black;
    overflow: hidden;
    border: 1px solid var(--color-text);
    transition: all 0.3s ease;
    &.dark {
        border: 1px solid var(--color-background);
        color: #fff;
    }
    &__wrapper {
        height: 50px;
        width: 250px;
    }
    &__text {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        margin-top: 25px;
        text-decoration: none;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
        background: var(--color-text);
        transition: all 0.3s ease;
    }
    &.dark::before {
        background: var(--color-background);
    }
    &.dark:hover {
        color: var(--color-background);
    }
    &:hover::before{
        left: 100%;
    }
    &:hover {
        color: var(--color-text);
        transform: scale(1.05)
    }
}