.link-custom {
    display: inline-block;
    color: var(--color-active);
    margin-top: 20px;
}

.link-wrapper {
    display: inline-block;
    width: max-content;
    height: max-content;
}