.header {
    height: 80px;
    background: #303841;
    color: #fff;
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    backdrop-filter: blur(10px);

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;

    }
    &__localization {
        @media(max-width: 700px) {
            margin-left: auto;
          }
    }
}