.about {
    background: var(--color-background);

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        text-align: center;
    }
}