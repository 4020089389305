.team-list {
    margin: 100px 0;

    &__list {
        display: flex;
        gap: 50px;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-top: 30px;
    }
}