.section-promo {
    position: relative;
    overflow: hidden;
    height: 300px;

    &::after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top:0;
        bottom: 0;
        background-color: rgba($color: #000000, $alpha: 0.5);
    }
    &__img {
        position: absolute;
        left: 0;
        top: -140%;
        bottom: 0;
        right: 0;
    }

    &__title {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 100%;
    }

}