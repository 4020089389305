.submenu {
    max-height: 0;
    width: max-content;
    overflow: hidden;
    position: absolute;
    line-height: 1.7;
    top: 20px;
    left: 0;
    background: var(--color-alter);
    padding: 0 20px;
    transition: 0.3s all ease;
    backdrop-filter: blur(8px);
 

    &.opened {
        max-height: 400px;
        padding: 20px 20px;
    }

    &__link {
        text-decoration: none;
        color: var(--color-text);
        position: relative;
        
    }
    &__link::after{
        position: absolute;
        content: '';
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 1.5px;
        background: var(--color-active);
        transition: 0.3s all ease;
    }
    &__link:hover::after{
        width: 100%;
    }

}