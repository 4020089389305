@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');


body {
    background: #303841;
    color: var(--color-text);
    line-height: 1.3;
    font-family: 'Lato', sans-serif;
}

.container {
    max-width: 1140px;
    height: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

#root {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main {
    flex: 1 0 auto;
    background: #fff;
}

section {
    padding: 100px 0 !important;
}

