.services-item {
    display: flex;
    // height: 380px;
    height: 700px;
    background: rgb(242,242,242);
    align-items: center;
    overflow: hidden;
    @media(max-width: 927px) {
        flex-direction: column;
        height: max-content;
    }
    &.reversed img {
        order: 2;
    }
    &__img {
        display: block;
        width: 50%;
        height: 100%;
        object-fit: cover;
        @media(max-width: 927px) {
           width: 100%;
           order: 2;
        }
    }
    &__descr {
        width: 50%;
        padding: 70px;
        @media(max-width: 927px) {
            width: 100%;
            padding: 70px 0px;
         }
    }

    &__text {
        font-size: 16px;
        line-height: 2;
        font-weight: 300;
        color: var(--color-text3);
    }
}
